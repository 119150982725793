import { ButtonLink } from "src/Components/Atoms/Button"
import { IDoc } from "src/Types/bukken.d"
import styled from "styled-components"


export const Docs = ({ docs } : {docs?:IDoc[]}) => {

    const SLink = styled.a`
        border: solid 1px ${props => props.theme.colors.grayB};
        display: inline-block;
        padding:10px 20px;
        font-size: ${props => props.theme.fontSize.btn};
        font-weight: bold;
        &.blue{
            border: solid 1px ${props => props.theme.colors.blue};
            color: ${props => props.theme.colors.blue};
            &:hover{
                background:${props => props.theme.colors.blue};
                color: #fff;
            }
        }
        &.red{
            border: solid 1px  ${props => props.theme.colors.red};
            color: ${props => props.theme.colors.red};
            &:hover{
                background:${props => props.theme.colors.red};
                color: #fff;
            }
    }
    `
    return (
        <SDiv>
            {
                docs && docs.length > 0 ? 
                <SLink 
                    className="blue"
                    rel="noreferrer" 
                    href={`${process.env.REACT_APP_SYSDIR}/${docs[0].dir}/${docs[0].name}`}
                    target="_blank">
                    物件概要（PDF）
                </SLink>
                :  
                <></>
            }  
        </SDiv>
    )
}

const SDiv = styled.div`
    margin-bottom: 30px;
    
`